// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Logout_flex__8tKVF {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.Logout_content__pfc4W {\n  width: 65%;\n  margin: 0 auto;\n  text-align: center;\n}\n\n.Logout_content__pfc4W h2 {\n  font-size: 23px;\n  font-family: var(--poppins-font);\n  margin-top: 2rem;\n  font-weight: 400;\n  line-height: 34px;\n}\n\n.Logout_flex__8tKVF button {\n  width: 47%;\n  border: 0;\n  cursor: pointer;\n  margin: 3rem 0;\n  border: 1px solid #707070;\n  border-radius: 10px;\n  padding: 0.5rem 1rem;\n}\n.Logout_typo__WXjuX {\n  font-family: var(--poppins-font);\n}\n", "",{"version":3,"sources":["webpack://./src/components/Logout/Logout.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;AACA;EACE,UAAU;EACV,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,gCAAgC;EAChC,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,SAAS;EACT,eAAe;EACf,cAAc;EACd,yBAAyB;EACzB,mBAAmB;EACnB,oBAAoB;AACtB;AACA;EACE,gCAAgC;AAClC","sourcesContent":[".flex {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.content {\n  width: 65%;\n  margin: 0 auto;\n  text-align: center;\n}\n\n.content h2 {\n  font-size: 23px;\n  font-family: var(--poppins-font);\n  margin-top: 2rem;\n  font-weight: 400;\n  line-height: 34px;\n}\n\n.flex button {\n  width: 47%;\n  border: 0;\n  cursor: pointer;\n  margin: 3rem 0;\n  border: 1px solid #707070;\n  border-radius: 10px;\n  padding: 0.5rem 1rem;\n}\n.typo {\n  font-family: var(--poppins-font);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flex": "Logout_flex__8tKVF",
	"content": "Logout_content__pfc4W",
	"typo": "Logout_typo__WXjuX"
};
export default ___CSS_LOADER_EXPORT___;
