// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Pricing_content__73vBM {\n  width: 100%;\n  height: 100%;\n  overflow-y: scroll;\n  margin-bottom: 2rem;\n}\n.Pricing_content__73vBM::-webkit-scrollbar {\n  display: none;\n}\n\n.Pricing_content__73vBM {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n.Pricing_content__73vBM h4 {\n  font-size: 0.9rem;\n  font-weight: 500;\n  margin-top: 2rem;\n  margin-bottom: 0.8rem;\n}\n.Pricing_editor__AqEQS {\n  height: 350px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ContentManagement/Pricing/Pricing.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,aAAa;AACf;;AAEA;EACE,wBAAwB;EACxB,qBAAqB;AACvB;AACA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;EAChB,qBAAqB;AACvB;AACA;EACE,aAAa;AACf","sourcesContent":[".content {\n  width: 100%;\n  height: 100%;\n  overflow-y: scroll;\n  margin-bottom: 2rem;\n}\n.content::-webkit-scrollbar {\n  display: none;\n}\n\n.content {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n.content h4 {\n  font-size: 0.9rem;\n  font-weight: 500;\n  margin-top: 2rem;\n  margin-bottom: 0.8rem;\n}\n.editor {\n  height: 350px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "Pricing_content__73vBM",
	"editor": "Pricing_editor__AqEQS"
};
export default ___CSS_LOADER_EXPORT___;
