// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdminProfile_content__dxenz {\n  width: 100%;\n  display: flex;\n}\n\n.AdminProfile_leftContent__5DIzS {\n  width: 40%;\n  margin-right: 40px;\n}\n.AdminProfile_leftContent__5DIzS img {\n  width: 100%;\n  aspect-ratio: 4/3;\n}\n.AdminProfile_rightContent__plpyW {\n  width: 30%;\n}\n\n.AdminProfile_textField__d\\+CQb {\n  margin: 10px 0 !important;\n}\n.AdminProfile_leftContent__5DIzS h3 {\n  font-weight: 600;\n  font-size: 0.8rem;\n  width: 100%;\n  height: 200px;\n  cursor: pointer;\n  text-align: center;\n  color: #f8cd46;\n  text-decoration: underline;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Profile/AdminProfile/AdminProfile.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,UAAU;AACZ;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;EACX,aAAa;EACb,eAAe;EACf,kBAAkB;EAClB,cAAc;EACd,0BAA0B;AAC5B","sourcesContent":[".content {\n  width: 100%;\n  display: flex;\n}\n\n.leftContent {\n  width: 40%;\n  margin-right: 40px;\n}\n.leftContent img {\n  width: 100%;\n  aspect-ratio: 4/3;\n}\n.rightContent {\n  width: 30%;\n}\n\n.textField {\n  margin: 10px 0 !important;\n}\n.leftContent h3 {\n  font-weight: 600;\n  font-size: 0.8rem;\n  width: 100%;\n  height: 200px;\n  cursor: pointer;\n  text-align: center;\n  color: #f8cd46;\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "AdminProfile_content__dxenz",
	"leftContent": "AdminProfile_leftContent__5DIzS",
	"rightContent": "AdminProfile_rightContent__plpyW",
	"textField": "AdminProfile_textField__d+CQb"
};
export default ___CSS_LOADER_EXPORT___;
