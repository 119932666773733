// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Terms_content__pBxWD{\n    width: 100%;\n    height: 100%;\n    margin-bottom: 2rem;\n}\n.Terms_content__pBxWD h4{\n    font-size: 0.9rem;\n    font-weight: 500;\n    margin-top: 2rem;\n    margin-bottom: 0.8rem;\n}\n.Terms_editor__iilQT {\n    height: 350px;\n  }\n  ", "",{"version":3,"sources":["webpack://./src/components/ContentManagement/Terms/Terms.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;IAChB,qBAAqB;AACzB;AACA;IACI,aAAa;EACf","sourcesContent":[".content{\n    width: 100%;\n    height: 100%;\n    margin-bottom: 2rem;\n}\n.content h4{\n    font-size: 0.9rem;\n    font-weight: 500;\n    margin-top: 2rem;\n    margin-bottom: 0.8rem;\n}\n.editor {\n    height: 350px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "Terms_content__pBxWD",
	"editor": "Terms_editor__iilQT"
};
export default ___CSS_LOADER_EXPORT___;
