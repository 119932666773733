// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Story_media__bkTrc {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 40%;\n  margin: 1rem 0;\n  padding: 0.4rem 0;\n  border-bottom: solid 1px gray;\n}\n.Story_media__bkTrc p {\n  font-size: 0.9rem;\n  opacity: 0.3;\n}\n.Story_icon__VP-6D {\n  cursor: pointer;\n}\n.Story_uploadedMedia__SBY1q {\n  max-width: 70px;\n  border-radius: 10px;\n  aspect-ratio: 1;\n}\n.Story_imgop__a2nys {\n  background-image: url(\"https://homesharebucket.s3.ap-south-1.amazonaws.com/Img4627222.322668638.png\");\n  background-repeat: no-repeat;\n  background-size: contain;\n  width: 100px;\n  border: solid 1px black;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ContentManagement/Story/Story.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,UAAU;EACV,cAAc;EACd,iBAAiB;EACjB,6BAA6B;AAC/B;AACA;EACE,iBAAiB;EACjB,YAAY;AACd;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;EACf,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,qGAAqG;EACrG,4BAA4B;EAC5B,wBAAwB;EACxB,YAAY;EACZ,uBAAuB;AACzB","sourcesContent":[".media {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 40%;\n  margin: 1rem 0;\n  padding: 0.4rem 0;\n  border-bottom: solid 1px gray;\n}\n.media p {\n  font-size: 0.9rem;\n  opacity: 0.3;\n}\n.icon {\n  cursor: pointer;\n}\n.uploadedMedia {\n  max-width: 70px;\n  border-radius: 10px;\n  aspect-ratio: 1;\n}\n.imgop {\n  background-image: url(\"https://homesharebucket.s3.ap-south-1.amazonaws.com/Img4627222.322668638.png\");\n  background-repeat: no-repeat;\n  background-size: contain;\n  width: 100px;\n  border: solid 1px black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"media": "Story_media__bkTrc",
	"icon": "Story_icon__VP-6D",
	"uploadedMedia": "Story_uploadedMedia__SBY1q",
	"imgop": "Story_imgop__a2nys"
};
export default ___CSS_LOADER_EXPORT___;
