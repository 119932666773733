// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PrivacyPolicy_content__N0A\\+o {\n  width: 100%;\n  height: 100%;\n  overflow-y: scroll;\n  margin-bottom: 2rem;\n}\n.PrivacyPolicy_content__N0A\\+o::-webkit-scrollbar {\n  display: none;\n}\n.PrivacyPolicy_content__N0A\\+o {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n.PrivacyPolicy_content__N0A\\+o h4 {\n  font-size: 0.9rem;\n  font-weight: 500;\n  margin-top: 2rem;\n  margin-bottom: 0.8rem;\n}\n.PrivacyPolicy_editor__WuRf7 {\n  height: 350px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ContentManagement/PrivacyPolicy/PrivacyPolicy.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,aAAa;AACf;AACA;EACE,wBAAwB;EACxB,qBAAqB;AACvB;AACA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;EAChB,qBAAqB;AACvB;AACA;EACE,aAAa;AACf","sourcesContent":[".content {\n  width: 100%;\n  height: 100%;\n  overflow-y: scroll;\n  margin-bottom: 2rem;\n}\n.content::-webkit-scrollbar {\n  display: none;\n}\n.content {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n.content h4 {\n  font-size: 0.9rem;\n  font-weight: 500;\n  margin-top: 2rem;\n  margin-bottom: 0.8rem;\n}\n.editor {\n  height: 350px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "PrivacyPolicy_content__N0A+o",
	"editor": "PrivacyPolicy_editor__WuRf7"
};
export default ___CSS_LOADER_EXPORT___;
