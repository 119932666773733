// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Blogs_content__E2g2a {\n  width: 100%;\n  height: 100%;\n}\n\n.Blogs_content__E2g2a svg {\n  margin-right: 10px;\n  cursor: pointer;\n}\n.Blogs_actionButton__O736W {\n  display: flex;\n  align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ContentManagement/Blogs/Blogs.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".content {\n  width: 100%;\n  height: 100%;\n}\n\n.content svg {\n  margin-right: 10px;\n  cursor: pointer;\n}\n.actionButton {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "Blogs_content__E2g2a",
	"actionButton": "Blogs_actionButton__O736W"
};
export default ___CSS_LOADER_EXPORT___;
