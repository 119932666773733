// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Faq_content__Hzd3N{\n    width: 100%;\n    height: 100%;\n}\n\n.Faq_content__Hzd3N svg{\n    margin-right: 10px;\n    cursor: pointer;\n}\n.Faq_actionButton__kmkLW{\n    display: flex;\n    align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/components/ContentManagement/Faq/Faq.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".content{\n    width: 100%;\n    height: 100%;\n}\n\n.content svg{\n    margin-right: 10px;\n    cursor: pointer;\n}\n.actionButton{\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "Faq_content__Hzd3N",
	"actionButton": "Faq_actionButton__kmkLW"
};
export default ___CSS_LOADER_EXPORT___;
