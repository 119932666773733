// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Authentication_content__60VRi {\n  width: 38%;\n}\n.Authentication_textField__xgAg6 {\n  margin: 10px 0 !important;\n}\n.Authentication_content__60VRi input {\n  font-family: var(--poppins-font);\n  font-size: 0.9rem;\n  color: #000000;\n}\n.Authentication_content__60VRi input::placeholder {\n  font-family: var(--poppins-font);\n  font-size: 0.9rem;\n  color: #000000;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Profile/Authentication/Authentication.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,gCAAgC;EAChC,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,gCAAgC;EAChC,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".content {\n  width: 38%;\n}\n.textField {\n  margin: 10px 0 !important;\n}\n.content input {\n  font-family: var(--poppins-font);\n  font-size: 0.9rem;\n  color: #000000;\n}\n.content input::placeholder {\n  font-family: var(--poppins-font);\n  font-size: 0.9rem;\n  color: #000000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "Authentication_content__60VRi",
	"textField": "Authentication_textField__xgAg6"
};
export default ___CSS_LOADER_EXPORT___;
