// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Testimonial_content__9ogQ5{\n    width: 100%;\n    height: 100%;\n}\n\n.Testimonial_content__9ogQ5 svg{\n    margin-right: 10px;\n    cursor: pointer;\n}\n.Testimonial_actionButton__Urb6s{\n    display: flex;\n    align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/components/ContentManagement/Testimonial/Testimonial.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".content{\n    width: 100%;\n    height: 100%;\n}\n\n.content svg{\n    margin-right: 10px;\n    cursor: pointer;\n}\n.actionButton{\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "Testimonial_content__9ogQ5",
	"actionButton": "Testimonial_actionButton__Urb6s"
};
export default ___CSS_LOADER_EXPORT___;
